export class Session {
    auth;
    authState;

    /** @private */
    user = null;

    /** @private */
    roles = new Set();

    /** @private */
    permissions = new Set();

    constructor(user, permissions, auth, env) {
        this.auth = auth;
        this.authState = auth.state;
        this.user = user;
        this.roles = new Set(user ? user.roles : []);
        this.permissions = user
            ? this.createPermissions(permissions, env)
            : new Set();
    }

    signOut() {
        return this.auth.signOut();
    }

    /**
     * @param {import('./UserPermission').UserPermission} permission
     * @returns {boolean}
     */
    hasPermission(permission) {
        return this.permissions.has(permission);
    }

    /** @private */
    createPermissions(definitions, env) {
        const isPrimary = this.user.organizations.some(
            (org) => 'primary' in org && org.primary === true
        );

        return Object.entries(definitions).reduce(
            (acc, [permission, rules]) => {
                const matches = rules.some((x) => {
                    if (typeof x === 'string') {
                        return this.roles.has(x);
                    }

                    return (
                        this.roles.has(x.role) &&
                        isPrimary === x.isPrimary &&
                        (Array.isArray(x.env) ? x.env.includes(env) : true)
                    );
                });

                if (matches) {
                    acc.add(permission);
                }

                return acc;
            },
            new Set()
        );
    }
}
